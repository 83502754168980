*, *:before, *::after {
	box-sizing: border-box;
	user-drag: none; 
	user-select: none;
	-moz-user-select: none;
	-webkit-user-drag: none;
	-webkit-user-select: none;
	-ms-user-select: none;
}

body {
	font: 14px "Century Gothic", Futura, sans-serif;
	margin: 20px;
}

ol, ul {
	padding-left: 30px;
}

.board-row:after {
	clear: both;
	content: "";
	display: table;
}

.status {
	font-size: 30px;
	margin-bottom: 10px;
	text-align: center;
}

.status .face {
	display: inline-block;
	cursor: pointer;
}

.errors {
	background: #c00;
	color: #fff;
	display: none;
	margin: -20px -20px 20px;
	padding: 20px;
	white-space: pre-wrap;
}


/* Cells
---------------------------------------------------------- */
.cell {
	background: #50cdff;
	background-size: contain;
	border: none;
	border-radius: 4px;
	cursor: pointer;
	float: left;
	font-size: 24px;
	font-weight: bold;
	line-height: 40px;
	height: 40px;
	margin: 2px;
	padding: 0;
	text-align: center;
	width: 40px;
}

.cell:focus {
	outline: none;
}

.cell.open {
	background: #fff;
}

.cell.bomb {
	background-color: #f00;
}

.cell.flagged {
	background: #fff569;
}

.cell.pushed {
	background-color: #b9b9b9;
	background-image: none;
}

.cell.near-1 {
	color: #0200fb;
}

.cell.near-2 {
	color: #017e00;
}

.cell.near-3 {
	color: #fd0100;
}

.cell.near-4 {
	color: #010080;
}

.cell.near-5 {
	color: #810101;
}

.cell.near-6 {
	color: #008180;
}

.cell.near-7 {
	color: #000;
}

.cell.near-8 {
	color: #808080;
}

.game-container {
	display: flex;
	flex-direction: row;
	justify-content: center;
}

.game-board {
	width: 440px;
}

.minesweeper {
	background: #242424;
	border-radius: 4px;
	padding: 30px;
}

.game-info {
	margin-left: 20px;
}